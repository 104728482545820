.Card {
    margin: 0 5px 20px 5px;
    padding: 10px 20px 10px 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 10px;
}

.Card p {
    text-align: justify;
    line-height: 24px;
}

.CardTitle {
    display: flex;
    flex-direction: column;
}

.CardTitle h3 {
    margin-bottom: 0px;
}

.CardTtile i {
    color: #86858C;
}