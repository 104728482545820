@import url('https://fonts.googleapis.com/css?family=Oxygen');
@font-face {
  font-family: "UniversLight";
  src: url("./res/font/Univers_Next/UniversNextPro-Light.ttf") format("truetype");
}

body {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  font-family: 'Oxygen', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.Section {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 40px;
  text-align: left;
}

.ProfessionalExperience {
  margin-bottom: 30px;
}

.ProfessionalExperienceHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.ProfessionalApplications {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .Section {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 40px;
    text-align: left;
  }
}