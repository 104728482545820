@import url(https://fonts.googleapis.com/css?family=Oxygen);
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Card {
    margin: 0 5px 20px 5px;
    padding: 10px 20px 10px 20px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 10px;
}

.Card p {
    text-align: justify;
    line-height: 24px;
}

.CardTitle {
    display: flex;
    flex-direction: column;
}

.CardTitle h3 {
    margin-bottom: 0px;
}

.CardTtile i {
    color: #86858C;
}
.Chip {
    color: white;
    background-color: #000F3D;
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
    font-weight: 600;
    background-clip: border-box;
}

.RoundChip {
    border: 2px solid white;
    color: white;
    margin: 4px 4px 4px 4px;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    font-weight: 600;
    background-clip: border-box;
    mask-type: alpha;
    background-image: -webkit-linear-gradient(top left, green, teal, indigo, violet);
    background-image: linear-gradient(to bottom right, green, teal, indigo, violet);
    background-attachment: fixed;

}

a.RoundChip {
    text-decoration: none;
}

a:hover.RoundChip  {
    color: inherit;
    mask-type: alpha;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.RoundChip a {
}

.ChipsCloud {
    background-color: white;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ChipsCloud .RoundChip {
    margin-right: 15px;
}


a {
    text-decoration: none;
}

.Application {
    display: flex;
    text-decoration: none;
    font-size: 14pt;
    font-weight: 600;
    color: black;
}

.Application img {
    width: 55px; 
    height: 55px; 
    border-radius: 15px; 
    margin-right: 15px;
}
.ApplicationsTray {
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 640px) {
    .ApplicationsTray {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}
@font-face {
  font-family: "UniversLight";
  src: url(/static/media/UniversNextPro-Light.39e48e5c.ttf) format("truetype");
}

body {
  margin-left: auto;
  margin-right: auto;
  max-width: 900px;
  font-family: 'Oxygen', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.Section {
  margin-left: 45px;
  margin-right: 45px;
  margin-bottom: 40px;
  text-align: left;
}

.ProfessionalExperience {
  margin-bottom: 30px;
}

.ProfessionalExperienceHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.ProfessionalApplications {
  display: flex;
}

@media only screen and (max-width: 600px) {
  .Section {
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 40px;
    text-align: left;
  }
}
.Separator {
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 80px;
    border: 1px solid white;
    border-radius: 3px;
    height: 4px;
    mask-type: alpha;
    background-clip: border-box;
    background-image: -webkit-linear-gradient(top left, green, teal, indigo, violet);
    background-image: linear-gradient(to bottom right, green, teal, indigo, violet);
    background-attachment: fixed;
}
.App-Bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 25px;
  }

.App-Avatar {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: 10px 20px 10px 20px;
}
  
.App-Caption {
  font-size: 24pt;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .App-Avatar {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    margin: 10px 20px 10px 20px;
  }

  .App-Caption {
    font-size: 12pt;
    text-align: left;
  }
}
