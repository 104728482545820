.ApplicationsTray {
    display: flex;
    justify-content: space-around;
}

@media screen and (max-width: 640px) {
    .ApplicationsTray {
        margin-top: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }
}