.Separator {
    margin-left: 25px;
    margin-right: 25px;
    margin-bottom: 80px;
    border: 1px solid white;
    border-radius: 3px;
    height: 4px;
    mask-type: alpha;
    background-clip: border-box;
    background-image: linear-gradient(to bottom right, green, teal, indigo, violet);
    background-attachment: fixed;
}