.Chip {
    color: white;
    background-color: #000F3D;
    padding: 2px 4px 2px 4px;
    border-radius: 4px;
    font-weight: 600;
    background-clip: border-box;
}

.RoundChip {
    border: 2px solid white;
    color: white;
    margin: 4px 4px 4px 4px;
    padding: 4px 8px 4px 8px;
    border-radius: 4px;
    font-weight: 600;
    background-clip: border-box;
    mask-type: alpha;
    background-image: linear-gradient(to bottom right, green, teal, indigo, violet);
    background-attachment: fixed;

}

a.RoundChip {
    text-decoration: none;
}

a:hover.RoundChip  {
    color: inherit;
    mask-type: alpha;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.RoundChip a {
}

.ChipsCloud {
    background-color: white;
    padding: 0px;
    margin: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ChipsCloud .RoundChip {
    margin-right: 15px;
}

