a {
    text-decoration: none;
}

.Application {
    display: flex;
    text-decoration: none;
    font-size: 14pt;
    font-weight: 600;
    color: black;
}

.Application img {
    width: 55px; 
    height: 55px; 
    border-radius: 15px; 
    margin-right: 15px;
}