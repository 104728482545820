.App-Bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 25px;
  }

.App-Avatar {
    border-radius: 50%;
    width: 120px;
    height: 120px;
    margin: 10px 20px 10px 20px;
}
  
.App-Caption {
  font-size: 24pt;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .App-Avatar {
    border-radius: 50%;
    width: 75px;
    height: 75px;
    margin: 10px 20px 10px 20px;
  }

  .App-Caption {
    font-size: 12pt;
    text-align: left;
  }
}